angular
    .module('annexaApp')
    .factory('AnnexaDocumentActionsButtonFactory',['HelperService', '$rootScope', 'DccumentsFactory', 'AnnexaPermissionsFactory', 'AnnexaEntityFactory', 'globalModals', '$filter', 'AnnexaFormlyFactory', 'AnnexaModalFactory', '$http', 'DialogsFactory', 'RestService', 'ErrorFactory', 'TramFactory', 'SignLocalFactory', '$q', 'GlobalDataFactory', 'RegFactory', 'NotificationFactory', 'SecFactory', 'Language', '$state', 'CommonService', 'TramNewFactory', 
   	function(HelperService, $rootScope, DccumentsFactory, AnnexaPermissionsFactory, AnnexaEntityFactory, globalModals, $filter, AnnexaFormlyFactory, AnnexaModalFactory, $http, DialogsFactory, RestService, ErrorFactory, TramFactory, SignLocalFactory, $q, GlobalDataFactory, RegFactory, NotificationFactory, SecFactory, Language, $state, CommonService, TramNewFactory) {
        var factory = {};

        var signModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.sign.pending'").toArray();
        var regOutputModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.reg.output'").toArray();

        //region Show Options

        var isInStates = function(states, document) {
            if(!Array.isArray(states)) {
                states = [states];
            }

            return _.contains(states, document.docStatus);
        }

        var havePermissions = function(permission, document) {
            if(document && document.profiles) {
                return AnnexaPermissionsFactory.haveSinglePermissionDual(permission, document.profiles);
            }else{
                return AnnexaPermissionsFactory.haveSinglePermissionDual(permission, undefined);
            }
        }
        
        var havePublishPermissions = function(permission){
        	var havePublishPermission = false;
        	if(permission){
        		havePublishPermission = havePermissions(permission);
        	}else{
        		havePublishPermission = havePermissions('publish_diaris_oficials');
                if(!havePublishPermission){
                	havePublishPermission = havePermissions('publish_perfil_contractant');
                }
                if(!havePublishPermission){
                	havePublishPermission = havePermissions('publish_carpeta_ciutadana');
                }
                if(!havePublishPermission){
                	havePublishPermission = havePermissions('publish_semic_e_tauler');
                }
                if(!havePublishPermission){
                	havePublishPermission = havePermissions('publish_semic_seu_electronica');
                }
                if(!havePublishPermission){
                	havePublishPermission = havePermissions('publish_semic_video_actas');
                }
        	}
        	return havePublishPermission;
        }
        
        var isInDocType = function(docTypes, document) {
            if(!Array.isArray(docTypes)) {
            	docTypes = [docTypes];
            }
            return _.contains(docTypes, document.type.acronym);
        }

        factory.isDocumentConvertibleToPdf = function(document, checkProtected){
        	if (document.hasPdfRendition && document.hasPdfRendition == true) {
        		if(checkProtected){
                    if(document.docStatus == 'COMPLETE' && !document.idPDFDocumentManager){
                        return false;
                    } else {
                    	return true;
                    }
                } else {
                	return true;
                }
        	} else {
        		return false;
        	} 
        	
        }

        factory.getShowOptions = function(document, dossierTransactionDocument, actualTransaction, list, disabled, module, dossier) {
            var availableOptions = ['SendToSign', 'CreateOutputReg', 'Complete', 'Publish', 'CreateDiligenceStamp', 'RemoveDocuments', 'DownloadSelected'];
            if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.show_notification_functionality && $rootScope.app.configuration.show_notification_functionality.value){
               if(!module || module != 'SEC'){
            	   availableOptions.push('Notification');
               }
            }
			if(module && module == 'EXPED'){
				availableOptions.push('AssociateDocumentToThird');
			}
            var retOptions = [];
			if($state.current.name != 'annexa.tram.view.viewdossier'  && $state.current.name != 'annexa.tram.dossierRightAccess.viewdossier' && $state.current.name != 'annexa.archive.finally.see'  && $state.current.name != 'annexa.archive.close.see'  && $state.current.name != 'annexa.archive.archive.see') {
	            _.forEach(availableOptions, function(option) {
	                if(factory.showOption(option, document, dossierTransactionDocument, actualTransaction, list, disabled, module, undefined, undefined, true, dossier)) {
	                    retOptions.push(option);
	                }
	            });
			}else if($state.current.name == 'annexa.tram.view.viewdossier' && dossier && dossier.dossierStatus == 'FINISHED'){
                if(factory.showOption('Publish', document, dossierTransactionDocument, actualTransaction, list, disabled, module, undefined, undefined, true, dossier)) {
                    retOptions.push('Publish');
                }
			}

            return retOptions;
        }

        factory.showOption = function(option, document, dossierTransactionDocument, actualTransaction, list, disabled, module, secDocument, showManuallyAddStampDecree, showAssociateDocumentToThird, dossier) {
            switch (option) {
                case 'EditOnlineMicrosoft':
                    if(isInStates('NEW_AND_ATTACHMENT', document) && havePermissions('edit_document', document) &&  HelperService.canEditMimeType(document.fileTypeDoc) && document.fileTypeDoc != 'application/vnd.oasis.opendocument.text' && !document.physical) {
                        if(!dossierTransactionDocument) {
                            return true;
                        } else if(dossierTransactionDocument && actualTransaction) {
                            return true;
                        }
                    }

                    return false;
                case 'EditOnlineLibreOffice':
                    if(isInStates('NEW_AND_ATTACHMENT', document) && havePermissions('edit_document', document) &&  HelperService.canEditMimeType(document.fileTypeDoc) && $rootScope.app.configuration.show_libreoffice_edit.value && !document.physical) {
                        if(!dossierTransactionDocument) {
                            return true;
                        } else if(dossierTransactionDocument && actualTransaction) {
                            return true;
                        }
                    }

                    return false;
                case 'SendToSign':
                	if(signModule.length < 1){
                        return false;
                    }

                    if(isInStates(['COMPLETE', 'NEW_AND_ATTACHMENT', 'SIGNED'], document) && havePermissions('send_sign_document', document) && factory.isDocumentConvertibleToPdf(document, true) && !document.physical) {
                    	return true;
                    }

                    return false;
                case 'CreateDiligenceStamp':
                    if(signModule.length < 1){
                        return false;
                    }
                    var haveDiligenceStampType = function(){
                	    var haveDiligence = false;
                    	if($rootScope.LoggedUser && $rootScope.LoggedUser.userProfiles && $rootScope.LoggedUser.userProfiles.length > 0 && GlobalDataFactory.diligenceStampTypes && GlobalDataFactory.diligenceStampTypes.length > 0){
		                	_.forEach(GlobalDataFactory.diligenceStampTypes, function(type){
		                		if(type.profiles && type.profiles.length > 0){
		                			var profiles = $linq(type.profiles).intersect($rootScope.LoggedUser.userProfiles, function(x,y){
                                        if(y.profile){
                                            return (x.profile.id == y.profile.id);
                                        }
                                        else{
                                            return false;
                                        }
                                    }).toArray();
                                    if(profiles && profiles.length > 0){
                                    	haveDiligence = true
                                    }
		                		}
		                	});
		                }
		                return haveDiligence;
    				} 
                    
                    if(isInStates(['COMPLETE', 'NEW_AND_ATTACHMENT', 'SIGNED'], document) /*&& havePermissions('send_sign_document', document)*/ && factory.isDocumentConvertibleToPdf(document, true)  && !document.physical && haveDiligenceStampType()) {
                        if(!dossierTransactionDocument) {
                            return true;
                        } else if(dossierTransactionDocument && actualTransaction) {
                            return true;
                        }
                    }

                    return false;
                case 'CreateOutputReg':
                    if(regOutputModule.length < 1){
                        return false;
                    }
                    
                    if( document && document.type && document.type.id){
                		documentType = $linq(GlobalDataFactory.documentTypes).firstOrDefault(undefined, "x => x.id == "+document.type.id);
                		if(documentType && !documentType.includeRegisterEntry){
                			return false;
                		}
                	}

                    if((isInStates(['NEW_AND_ATTACHMENT', 'COMPLETE', 'PENDING_SIGN', 'SIGNED'], document) || (isInStates('NEW', document) &&  document.physical))&& havePermissions('new_output_entry_register')) {
                        return true;
                    }

                    return false;
                case 'Notification':
                	if( document && document.type && document.type.id){
                		documentType = $linq(GlobalDataFactory.documentTypes).firstOrDefault(undefined, "x => x.id == "+document.type.id);
                		if(documentType && !documentType.includeNotification){
                			return false;
                		}
                	}
                    if(isInStates(['NEW_AND_ATTACHMENT', 'COMPLETE', 'PENDING_SIGN', 'SIGNED'], document) &&  !document.physical && havePermissions('new_output_entry_register') && dossierTransactionDocument && dossier) {
                        if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.show_notification_functionality && $rootScope.app.configuration.show_notification_functionality.value){
                            return true;
                        }else{
                            return false;
                        }
                    }

                    return false;
                case 'Publish':
                    var pluginActives = false;
                    var havePermission = havePublishPermissions();
                    if(havePermission){
	                    if(GlobalDataFactory.publishPlugins && GlobalDataFactory.publishPlugins.length > 0) {
	                        _.forEach(GlobalDataFactory.publishPlugins, function (val) {
	                            if(!pluginActives){
	                            	if(havePublishPermissions(val.id)){
		                                if(val.availableStates && val.availableStates.length > 0){
		                                    pluginActives = $linq(val.availableStates).contains(document.docStatus);
		                                }
	                            	}
	                            }
	                        });
	                    }
	
	                    if(isInStates(['COMPLETE', 'NEW_AND_ATTACHMENT', 'PENDING_SIGN', 'SIGNED'], document) && pluginActives && !document.physical) {
	                        return true;
	                    }
                    }
                    return false;
                case 'Download':
                    if(document.docStatus != 'NEW' && !document.physical) {
                        return true;
                    }

                    return false;
                case 'DownloadPDF':
                    if(document.docStatus != 'NEW' && factory.isDocumentConvertibleToPdf(document, false) && !document.physical) {
                        return true;
                    }

                    return false;
                case 'GenerateZIP':
                	if(document && document.type && $rootScope.app.configuration.default_eni_values.eni_dossier_document_type == document.type.id && !document.physical){
            			return true;
            		}
                    return false;
                case 'Print':
                    if(document.docStatus != 'NEW' && !document.physical) {
                        return true;
                    }

                    return false;
                case 'Email':
                    if(document.docStatus != 'NEW' && document.currentDocument && $rootScope.app.configuration.show_send_mail_document && $rootScope.app.configuration.show_send_mail_document.value && !document.physical) {
                        return true;
                    }

                    return false;
                case 'Complete':
                    if((isInStates('NEW_AND_ATTACHMENT', document) || (isInStates('NEW', document) &&  document.physical))&& havePermissions('send_sign_document', document)) {
                        if(!dossierTransactionDocument) {
                            return true;
                        } else if(dossierTransactionDocument && actualTransaction) {
                            return true;
                        }
                    }

                    return false;
                case 'ReturnToDraft':
                    if(isInStates('PENDING_SIGN', document) && havePermissions('send_sign_document', document) && !document.physical){
                    	return true;
                    }else{
                    	return false;
                    }
                case 'Remove':
                	if(!dossierTransactionDocument && (!module || module != 'SEC')){
                		if(isInDocType(['ENIDOC', 'ENIEXP', 'SICRES'], document)) {
                        	if(havePermissions('delete_document_interoperable', document)) {
                        		return true;
                        	}
                        	return false;
                        }else if(isInStates(['NEW', 'NEW_AND_ATTACHMENT'], document) && havePermissions('delete_document', document)) {
                            return true;
                        }
                	}
                    return false;
                case 'RemoveDivider':
                    return
                        factory.showOption('Remove', document, dossierTransactionDocument, actualTransaction, list, disabled, module, dossier) &&
                        (
                            factory.showOption('EditOnlineMicrosoft', document, dossierTransactionDocument, actualTransaction, list, disabled, module, dossier) ||
                            factory.showOption('EditOnlineLibreOffice', document, dossierTransactionDocument, actualTransaction, list, disabled, module, dossier) ||
                            factory.showOption('SendToSign', document, dossierTransactionDocument, actualTransaction, list, disabled, module, dossier) ||
                            factory.showOption('CreateDiligenceStamp', document, dossierTransactionDocument, actualTransaction, list, disabled, module, dossier) ||
                            factory.showOption('CreateOutputReg', document, dossierTransactionDocument, actualTransaction, list, disabled, module, dossier) ||
                            factory.showOption('Notification', document, dossierTransactionDocument, actualTransaction, list, disabled, module, dossier) ||
                            factory.showOption('Publish', document, dossierTransactionDocument, actualTransaction, list, disabled, module, dossier) ||
                            factory.showOption('Download', document, dossierTransactionDocument, actualTransaction, list, disabled, module, dossier) ||
                            factory.showOption('GenerateZIP', document, dossierTransactionDocument, actualTransaction, list, disabled, module, dossier) ||
                            (!list && factory.showOption('Attach', document, dossierTransactionDocument, actualTransaction, list, disabled, module, dossier)) ||
                            (!list && factory.showOption('Replace', document, dossierTransactionDocument, actualTransaction, list, disabled, module, dossier)) ||
                            factory.showOption('Print', document, dossierTransactionDocument, actualTransaction, list, disabled, module, dossier) ||
                            factory.showOption('Email', document, dossierTransactionDocument, actualTransaction, list, disabled, module, dossier) ||
                            factory.showOption('Complete', document, dossierTransactionDocument, actualTransaction, list, disabled, module, dossier) ||
                            factory.showOption('ReturnToDraft', document, dossierTransactionDocument, actualTransaction, list, disabled, module, dossier)
                        );
                case 'RemoveDossierTransaction':
                	if(dossierTransactionDocument && actualTransaction && !disabled && !dossierTransactionDocument.publicDocument){
                		if(isInDocType(['ENIDOC', 'ENIEXP', 'SICRES'], document)) {
                        	if(havePermissions('delete_document_interoperable', document)) {
                        		return true;
                        	}
                        	return false;
                        }
                		else if(isInStates(['NEW', 'NEW_AND_ATTACHMENT', 'COMPLETE', 'PENDING_SIGN', 'SIGNED', 'REJECTED'], document) && havePermissions('delete_document', document)) {
                        	return true;
                        }
                	}
                    return false;
                case 'RemoveSecDocument':
                	if(module == 'SEC' && secDocument && secDocument.added) {
                		if(isInDocType(['ENIDOC', 'ENIEXP', 'SICRES'], document)) {
                        	if(havePermissions('delete_document_interoperable', document)) {
                        		return true;
                        	}
                        	return false;
                        } else if(isInStates(['NEW', 'NEW_AND_ATTACHMENT', 'COMPLETE', 'PENDING_SIGN', 'SIGNED', 'REJECTED'], document)) {
                        	return true
                        }
                	}
                    return false;
                case 'Attach':
                    if(isInStates('NEW', document) && havePermissions('edit_document', document) && !document.physical) {
                        return true;
                    }

                    return false;
                case 'Replace':
                    if(isInStates('NEW_AND_ATTACHMENT', document) && havePermissions('edit_document', document) && !document.physical) {
                        return true;
                    }

                    return false;
                case 'AllowGenerateFromContentDocumentType':
                	var documentType = undefined;
                	if( document && document.type && document.type.id){
                		documentType = $linq(GlobalDataFactory.documentTypes).firstOrDefault(undefined, "x => x.id == "+document.type.id);
                	}
                    if( documentType  && !document.physical && documentType.allowGenerateFromContentDocumentTypes && documentType.allowGenerateFromContentDocumentTypes.length > 0 && document.docStatus != 'NEW' && havePermissions('new_document', undefined)) {
                    	var haveProfiles = false;
                    	_.forEach(documentType.allowGenerateFromContentDocumentTypes, function(son){
        					var dtype = $linq(GlobalDataFactory.documentTypes).firstOrDefault(undefined, "x => x.id == "+son.documentTyperelatedJSONId);
        					if(dtype && dtype.documentTemplateSelection && dtype.documentTemplateSelection != 'NOT_REQUIRED'){
        		                if(dtype.profiles && dtype.profiles.length > 0 && $rootScope.LoggedUser && $rootScope.LoggedUser.userProfiles && $rootScope.LoggedUser.userProfiles.length > 0){
        		                	var profiles = $linq(dtype.profiles).intersect($rootScope.LoggedUser.userProfiles, function(x,y){
                                        if(y.profile){
                                            return (x.profile.id == y.profile.id);
                                        }
                                        else{
                                            return true;
                                        }
                                    }).toArray();
                                    if(profiles && profiles.length > 0){
                                    	haveProfiles = true;
                                    }
        		                }else if(!dtype.profiles || dtype.profiles.length == 0){
        		                	haveProfiles = true;
        		                }
        					}
        				});
                        return haveProfiles;
                    }

                    return false;
                case 'MarkAsWorkDocument':
                	if (dossierTransactionDocument && dossierTransactionDocument.relationType && dossierTransactionDocument.relationType == 'DOCUMENT') {
                		return true;
                	}
                	
                	return false;
                case 'MarkAsDocument':
                	if (dossierTransactionDocument && dossierTransactionDocument.relationType && dossierTransactionDocument.relationType == 'WORK_DOCUMENT') {
                		return true;
                	}
                	
                	return false;
                case 'SendToPaperBin':
                	if (dossierTransactionDocument && !dossierTransactionDocument.paperBin) {
                		return true;
                	}
                	return false;
                case 'UnbindDocument':
                	if (dossierTransactionDocument) {
                		return true;
                	}
                	return false;
                case 'RemoveFromPaperBin':
                	if (dossierTransactionDocument && dossierTransactionDocument.paperBin) {
                		return true;
                	}
                	return false;
                case 'ManuallyAddStampDecree':
					if(!document.physical){
	                	return showManuallyAddStampDecree;
					}else{
						return false;
					}
                case 'RegenerateDocument':
                	if(isInStates('NEW_AND_ATTACHMENT', document) && ((document.template && document.template.id) || (document.tramTemplate && document.tramTemplate.id) || (document.secTemplate && document.secTemplate.id && !isSecretaryDocumentType(document.type)))) {
                        if(!dossierTransactionDocument) {
                            return true;
                        } else if(dossierTransactionDocument && actualTransaction) {
                            return true;
                        }
                    }
					return false;
                case 'AssociateDocumentToThird':
                	return showAssociateDocumentToThird;
                case 'RemoveDocuments':
                	return true;
                case 'DownloadSelected':
                	if(!document.physical){
	                	return true;
					}else{
						return false;
					}
            }
        }

        //endregion

        //region Execute Options
		var isSecretaryDocumentType = function(type){
			var isSecretary = false;
			var documentTypes = $rootScope.app.configuration.sec_document_types;
			if(documentTypes){
				_.forEach(documentTypes, function(dt){
					if(type && type.id && _.contains(dt.id, type.id+"")){
						if(dt.acronym !== 'SDR' ){
							isSecretary = true;
						}
					}
				});
			}
			return isSecretary;
		}
        var editOnline = function (type, document) {
            DccumentsFactory.getEditOnlineUrl(document.idDocumentManager, type)
                .then(function(data) {
                    window.location.href = data;
                    broadcastDocumentActionButtonExecuted('editOnline', data);
                }).catch(function(error) {
                    broadcastDocumentActionButtonError('editOnline', error);
            })
        }

        var sendToSign = function (document, dossier) {
        	if(($state.current.name == 'annexa.doc.documents.all' || $state.current.name == 'annexa.doc.documents.type') && !Array.isArray(document)){
        		RestService.findOne('Document', document.id, 'annexa-document-actions-button').then(function(data) {
        			SignLocalFactory.sendToSignModal(undefined, [JSOG.decode(data.data)]);
                });
        	}else{
        		if (dossier) {
        			var dossierReceivers = [];
        			TramNewFactory.getDossierThirds(dossier.id).then(function(data) {
        				dossierReceivers = JSOG.decode(data);
        				SignLocalFactory.sendToSignModal(undefined, !Array.isArray(document) ? [document] : document, dossierReceivers);
        			}).catch(function(error) {
        			})
        		} else {
            		SignLocalFactory.sendToSignModal(undefined, !Array.isArray(document) ? [document] : document);
        		}
        	}
        }

        var createDiligenceStamp = function (document) {
        	SignLocalFactory.createDiligenceStamp((!Array.isArray(document) ? [document] : document));
        }
        
        var createRegisterEntry = function (document, transaction, dossier) {
        	var documentsToAdd = [];
			var thirds = [];
        	if (document && Array.isArray(document)) {
        		//Elimina los documentos repetidos
        		document = $linq(document).distinctBy("x => x.id").select("x => x").toArray();
        		_.forEach(document, function(doc){
        			documentsToAdd.push({document: doc, relationType:'DOCUMENT'});
        		});
        	}else if(document){
        		documentsToAdd.push({document: document, relationType:'DOCUMENT'});
        	}
        	
            var showModal = function(receivers) {
            	var tramClassification = undefined;
            	if(dossier && dossier.tramClassification) {
                	tramClassification = dossier.tramClassification;
                }
                RegFactory.newRegisterEntryModal('OUTPUT',
                    receivers,
                    ((documentsToAdd && documentsToAdd.length > 0) ? documentsToAdd: undefined),
                    !transaction ? undefined : [dossier], undefined, undefined, tramClassification
                );
            }

            var submitModalReceivers = function() {
                var self = this;

                var receivers = [];

                _.forEach(thirds, function(value) {
                    if(self.annexaFormly.model.modal_body['editReceiver_' + value.third.id] == true) {
                        receivers.push(value);
                    }
                });
                this.close();

                showModal(receivers); 
            }

            if(!transaction) {
                showModal([]);
            } else if(transaction && document.dossierThird) {
                showModal([document.dossierThird]);
            } else if(transaction) {
				TramNewFactory.getDossierThirds(dossier.id).then(function(data) {
	                var modal = angular.copy(globalModals.receiverEdit);
	                modal.model = { modal_body: {} };
	                modal.options = {};
	
	                modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.options = [];
					thirds = JSOG.decode(data);
	                _.forEach(thirds, function(value) {
	                    var receiverType = '';
	
	                    if(value.interested) {
	                        receiverType = $filter('translate')('global.literals.interested');
	                    } else if(value.representated) {
	                        var representated = '';
	
	                        if(value.representated.corporateName) {
	                            representated = '(' + value.representated.corporateName;
	                        } else {
	                            representated = '(' + value.representated.name + (value.representated.surname1Particles ? ' ' + value.representated.surname1Particles : '') + ' ' + value.representated.surename1 + (value.representated.surname2Particles ? ' ' + value.representated.surname2Particles : '') + ' ' + (value.representated.surename2 ? ' ' + value.representated.surename2 : '');
	                        }
	
	                        representated += '(' + value.representated.identificationDocument + '))';
	
	                        receiverType = $filter('translate')('global.literals.representative', { representated: representated });
	                    } else {
	                        receiverType = $filter('translate')('global.literals.representative', { representated: '' });
	                    }
	
	                    modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.options.push({
	                        value: value.third.id,
	                        label: value.third.thirdType == 'PHISICAL' ? (value.third.name + (value.third.surname1Particles ? ' ' + value.third.surname1Particles : '') + ' ' + value.third.surename1 + (value.third.surname2Particles ? ' ' + value.third.surname2Particles : '') + ' ' + (value.third.surename2 ? ' ' + value.third.surename2 : '')) : value.third.corporateName,
	                        labelHtml: receiverType
	                    });
	                });
	
	                AnnexaFormlyFactory.showModal('modalSelectReceivers', modal, submitModalReceivers, false);
				}).catch(function(error) {
				})
            }

        }

        var notification = function (dossierTransactionDocument, dossier) {
			var promises = [];
			promises.push(TramNewFactory.getDossierThirds(dossier.id));
        	promises.push(TramNewFactory.getDossierTransactionDocuments(dossier.id));
            $q.all(promises).then(function(data) {
		    	var archiveClassifications = CommonService.getTreeData(GlobalDataFactory.archiveClassifications, Language.getActiveColumn());
	            var newModal =  NotificationFactory.notificationNewModal(undefined, $linq(dossierTransactionDocument).select("x => x.document").toArray(), dossier, undefined, undefined, undefined, undefined, archiveClassifications,((data && data[0])?JSOG.decode(data[0]):undefined), ((data && data[1])?JSOG.decode(data[1]):undefined));
	
	            var submitNewNotification = function () {
	                NotificationFactory.getNewNotifications(this, newModal);
	            }
	
	            newModal.submitModal = submitNewNotification;
	
	            AnnexaFormlyFactory.showAnnexaFormModal('modalNewNotification', newModal);
			}).catch(function(error) {
			})
        }

        var publish = function(document, transaction) {
        	var havePermission = havePublishPermissions();
            if(havePermission){
	            var document = angular.copy(document);
	
	            var modal = angular.copy(globalModals.publishDocument);
	            modal.data = { document: document }
	            modal.originId = ((transaction && transaction.id)?transaction.id:undefined);
	            modal.submitLabel = 'global.literals.publish';
	            modal.submitFunction = function() {
	            }
	
	            AnnexaModalFactory.showModal('modalPublish', modal);
            }
        }

        var publishDocuments = function(documents, dossier) {
        	var havePermission = havePublishPermissions();
            if(havePermission){
	            var documents = angular.copy(documents);
	
	            var modal = angular.copy(globalModals.publishDocuments);
	            modal.data = { documents: documents };
	            modal.originId = ((dossier && dossier.id)?dossier.id:undefined);
	            modal.submitLabel = 'global.literals.publish';
	            modal.submitFunction = function() {
	            }
	
	            AnnexaModalFactory.showModal('modalPublish', modal);
            }
        }

        var download = function(doc, pdf) {
            $rootScope.loading(true);

            var href = "./api/repodocs/downloaddoc?id=";

            if (pdf) {
                if (doc.idPDFDocumentManager) {
                    href = href + doc.idPDFDocumentManager + '&mimetype=' + doc.filePDFTypeDoc;
                } else {
                    href = href + doc.idDocumentManager + '&mimetype=application/pdf&convertPDF=true';
                }
            } else {
                href = href + doc.idDocumentManager + '&mimetype=' + doc.fileTypeDoc;
            }

            $http.get(href, {responseType: 'arraybuffer'})
                .success(function (data, status, headers) {
                    var retDownload = {
                        file: undefined,
                        fileName: undefined,
                        linkElement: undefined,
                        clickEvent: undefined
                    }
                    headers = headers();

                    var contentType = headers['content-type'];
                    retDownload.file = new Blob([data], {type: contentType});

                    retDownload.fileName = doc.fileName;
                    if (pdf && !doc.fileName.endsWith('.pdf')) {
                        retDownload.fileName += '.pdf';
                    }


                    try {
                        if (window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(retDownload.file, retDownload.fileName);
                        } else {
                            retDownload.linkElement = document.createElement('a');
                            var url = window.URL.createObjectURL(retDownload.file);
                            retDownload.linkElement.setAttribute('href', url);
                            retDownload.linkElement.setAttribute("download", retDownload.fileName);
                            retDownload.clickEvent = new MouseEvent("click", {
                                "view": window,
                                "bubbles": true,
                                "cancelable": false
                            });
                            retDownload.linkElement.dispatchEvent(retDownload.clickEvent);
                        }
                        $rootScope.loading(false);
                    } catch (e) {
                        $rootScope.loading(false);
                        broadcastDocumentActionButtonError('download', e);
                    }
                    broadcastDocumentActionButtonExecuted('download', retDownload);
                }).error(function (error) {
                    $rootScope.loading(false);
                    broadcastDocumentActionButtonError('download', error);
            });
        }

        var generateZIP = function(doc){
    		if (doc && doc.id) {
	            var eniDocumentType = $linq(GlobalDataFactory.allDocumentTypes).firstOrDefault(undefined, "x => x.id == "+$rootScope.app.configuration.default_eni_values.eni_dossier_document_type);
	            if (eniDocumentType) {
	            	DialogsFactory.confirm('global.documents.new.audit.actionType.ENI', 'global.literals.generate_ZIP_ENI')
	                .then(function (data) {
	                	var fileContentsForDownload = function(headers, data) {
	                        var retDownload = {
	                            file: undefined,
	                            fileName: undefined,
	                            linkElement: undefined,
	                            clickEvent: undefined
	                        }

	                        var contentType = headers['content-type'];
	                        retDownload.file = new Blob([data], { type: contentType });
	                        retDownload.fileName = headers['content-disposition'].replace('attachment; filename="','').replace('"', '');

	                        try {
	                            if (window.navigator.msSaveOrOpenBlob) {
	                                window.navigator.msSaveOrOpenBlob(retDownload.file, retDownload.fileName);
	                            } else {
	                                retDownload.linkElement = document.createElement('a');
	                                var url = window.URL.createObjectURL(retDownload.file);
	                                retDownload.linkElement.setAttribute('href', url);
	                                retDownload.linkElement.setAttribute("download", retDownload.fileName);
	                                retDownload.clickEvent = new MouseEvent("click", {
	                                    "view": window,
	                                    "bubbles": true,
	                                    "cancelable": false
	                                });
	                                retDownload.linkElement.dispatchEvent(retDownload.clickEvent);
	                            }
	                            $rootScope.loading(false);
	                        } catch(e) {
	                            $rootScope.loading(false);
	                        }
	                    }
	                	
	                	$http({
	                        url: './api/doc/zipEni/' +doc.id,
	                        method: 'POST',
	                        responseType: 'arraybuffer'
	                    }).then(function(data) {
	                    	$rootScope.loading(false);
                    		fileContentsForDownload(data.headers(), data.data)
	                    }).catch(function(error) {
	                    	DialogsFactory.error( 'global.errors.unknownNew', $filter('translate')('DIALOGS_ERROR_MSG'));
                            console.log({msg: 'global.errors.unknownNew'});
	                    });
	                }).catch(function(error) {
			        });
	            }
        	}
        }
        
        var print = function(doc) {
            DccumentsFactory.getDocContent(doc.id, true)
                .then(function(data) {
                	var wnd = window.open(data, '_blank');
                	wnd.addEventListener('DOMContentLoaded', function(){wnd.print();});
                }).catch(function(error) {
                    broadcastDocumentActionButtonError('print', error);
            });
        }

        var complete = function(document) {
            DialogsFactory.confirm('global.documents.confirmCompleteDocumentTitle', 'global.documents.confirmCompleteDocumentContent')
                .then(function() {
                    $rootScope.loading(true);
                    var documents = !Array.isArray(document) ? [document] : document;

                    var promises = [];

                    _.forEach(documents, function(doc) {
                        promises.push(
                            DccumentsFactory.definitiveDocument( { id: doc.id })
                                .then(function (data) {
                                    $rootScope.loading(false);
                                    broadcastDocumentActionButtonExecuted('complete', data);
                                }).catch(function(error) {
                                    $rootScope.loading(false);
									if(error && error.message === "Is not valid document from template not found"){
										DialogsFactory.error($filter('translate')('global.documents.definitiveNoValidError', {name:doc.name}));
									}else{
										DialogsFactory.error($filter('translate')('global.documents.definitiveError', {name:doc.name}));
									}
                                    broadcastDocumentActionButtonError('complete', error);
                            })
                        );
                    });

                    $q.all(promises)
                        .then(function(data) {
                            $rootScope.loading(false);
                            if(promises.length == 1) {
                                broadcastDocumentActionButtonExecuted('complete', data[0]);
                            } else {
                                broadcastDocumentActionButtonExecuted('complete', data);
                            }
                        }).catch(function(error) {
                            $rootScope.loading(false);
                            broadcastDocumentActionButtonError('complete', error);
                    });
                })
        }

        var returnToDraft = function (document) {
            DialogsFactory.confirm('global.documents.backToDraftTitle', 'global.documents.backToDraftContent')
                .then(function() {
                    DccumentsFactory.returnToDraftDocument(document.id)
                        .then(function (data) {
                            broadcastDocumentActionButtonExecuted('returnToDraft', data);
                        }).catch(function(error) {
                        	if(error && error.message == 'Error book state modify'){
                        		DialogsFactory.error('global.books.view.errorReturnDraftSign');
                        	}else if(error && error.message == 'Error decree state modify'){
                        		DialogsFactory.error('global.books.view.errorReturnDraftSignDecree');
                        	}else{
                        		DialogsFactory.error('global.documents.backToDraftError');
                        	}
                            broadcastDocumentActionButtonError('returnToDraft', error);
                    });
                })
        }

        var deleteDocument = function (document) {
            RestService.checkDelete('./api/doc/check_delete/' + document.id)
                .then(function(data) {
                    if(data) {
                        DialogsFactory.confirm('global.documents.confirmDeleteDocumentTitle', 'global.documents.confirmDeleteDocumentContent')
                            .then(function () {
                                $rootScope.loading(true);
                                DccumentsFactory.deleteDocument(document.id)
                                    .then(function () {
                                        $rootScope.loading(false);
                                        broadcastDocumentActionButtonExecuted('delete', document.id);
                                    }).catch(function (error) {
                                        $rootScope.loading(false);
                                        broadcastDocumentActionButtonError('delete', error);
                                        DialogsFactory.error(ErrorFactory.getErrorMessage('documents', 'checkDelete', error.data ), $filter('translate')('DIALOGS_ERROR_MSG'));
                                });
                            });
                    }
                }).catch(function (error) {
                    DialogsFactory.error(ErrorFactory.getErrorMessage('documents', 'checkDelete', error.data ), $filter('translate')('DIALOGS_ERROR_MSG'));
            });
        }

        var deleteDossierTransactionDocument = function (document, transaction) {
            DialogsFactory.confirm('global.documents.confirmDeleteDocumentTitle', 'global.documents.confirmDeleteDocumentContent')
                .then(function() {
                    $rootScope.loading(true);
                    TramFactory.deleteDocument(transaction.id, document.id)
                        .then(function() {
                            $rootScope.loading(false);
                            broadcastDocumentActionButtonExecuted('deleteDossierTransactionDocument', {documentId:document.id, transactionId:transaction.id});
                        }).catch(function(error) {
                            $rootScope.loading(false);
                            broadcastDocumentActionButtonError('deleteDossierTransactionDocument', error);
                    });
                });
        }
        var deleteMultipleDossierTransactionDocuments = function (dossierTransactionDocuments) {        	
        	var documentsToDelete = [];
        	var documentsNotToDelete = [];
            var confirmBody = '';
            confirmBody = $filter('translate')('global.documents.confirmDeleteDocumentsListOk');
            confirmBody += '<br/><ul>';
        	_.forEach(dossierTransactionDocuments, function(docTransaction){
        		var doc = docTransaction.document;
        		if(doc && docTransaction.dossierTransactionIsActual && !docTransaction.publicDocument){
            		if(isInDocType(['ENIDOC', 'ENIEXP', 'SICRES'], doc)) {
                    	if(havePermissions('delete_document_interoperable', doc)) {
                    		confirmBody += '   <li>(' + doc.code + ') ' + doc.name + '</li>';
                    		documentsToDelete.push(docTransaction);
                    	} else {
                    		documentsNotToDelete.push(doc);
                    	}
                    }
            		else if(isInStates(['NEW', 'NEW_AND_ATTACHMENT', 'COMPLETE', 'PENDING_SIGN', 'SIGNED', 'REJECTED'], doc) && havePermissions('delete_document', doc)) {
            			confirmBody += '   <li>(' + doc.code + ') ' + doc.name + '</li>';
            			documentsToDelete.push(docTransaction);
                    } else {
                    	documentsNotToDelete.push(doc);
                    }
            	} else if(doc) {
            		documentsNotToDelete.push(doc);
            	}
			});
            confirmBody += '</ul>';
            
            if(documentsNotToDelete.length > 0) {
            	confirmBody += '<br/>';
                confirmBody += $filter('translate')('global.documents.confirmDeleteDocumentsListKO');
                confirmBody += '<br/><ul>';
            	_.forEach(documentsNotToDelete, function(document){
            		confirmBody += '   <li>(' + document.code + ') ' + document.name + '</li>';
    			});
                confirmBody += '</ul>';
            }
            
            DialogsFactory.confirm('global.documents.confirmDeleteDocumentTitle', confirmBody)
            	.then(function() {
            		$rootScope.loading(true);
                    TramFactory.deleteMultipleDocuments(documentsToDelete)
                        .then(function() {
                            $rootScope.loading(false);
                            broadcastDocumentActionButtonExecuted('deleteMultipleDossierTransactionDocument', {documents:documentsToDelete});
                        }).catch(function(error) {
                            $rootScope.loading(false);
                            broadcastDocumentActionButtonError('deleteMultipleDossierTransactionDocument', error);
                    });
            });
        	
        }
        var deleteMultipleSessionDocuments = function (secDocuments) {
        	var documentsToDelete = [];
        	var documentsNotToDelete = [];
            var confirmBody = '';
            confirmBody = $filter('translate')('global.documents.confirmDeleteDocumentsListOk');
            confirmBody += '<br/><ul>';
        	_.forEach(secDocuments, function(docSection){
        		var doc = docSection.document;
        		if(doc && !doc.publicDocument){
            		if(isInDocType(['ENIDOC', 'ENIEXP', 'SICRES'], doc)) {
                    	if(havePermissions('delete_document_interoperable', doc)) {
                    		confirmBody += '   <li>(' + doc.code + ') ' + doc.name + '</li>';
                    		documentsToDelete.push(docSection);
                    	} else {
                    		documentsNotToDelete.push(doc);
                    	}
                    }
            		else if(isInStates(['NEW', 'NEW_AND_ATTACHMENT', 'COMPLETE', 'PENDING_SIGN', 'SIGNED', 'REJECTED'], doc) && havePermissions('delete_document', doc)) {
            			confirmBody += '   <li>(' + doc.code + ') ' + doc.name + '</li>';
            			documentsToDelete.push(docSection);
                    } else {
                    	documentsNotToDelete.push(doc);
                    }
            	} else if(doc) {
            		documentsNotToDelete.push(doc);
            	}
			});
            confirmBody += '</ul>';
            
            if(documentsNotToDelete.length > 0) {
            	confirmBody += '<br/>';
                confirmBody += $filter('translate')('global.documents.confirmDeleteDocumentsListKO');
                confirmBody += '<br/><ul>';
            	_.forEach(documentsNotToDelete, function(document){
            		confirmBody += '   <li>(' + document.code + ') ' + document.name + '</li>';
    			});
                confirmBody += '</ul>';
            }
            
            DialogsFactory.confirm('global.documents.confirmDeleteDocumentTitle', confirmBody)
            	.then(function() {
            		$rootScope.loading(true);
                    SecFactory.deleteMultipleSessionDocuments(documentsToDelete)
                        .then(function() {
                            $rootScope.loading(false);
                            broadcastDocumentActionButtonExecuted('deleteMultipleDossierTransactionDocument', {documents:documentsToDelete});
                        }).catch(function(error) {
                            $rootScope.loading(false);
                            broadcastDocumentActionButtonError('deleteMultipleDossierTransactionDocument', error);
                    });
            });
        }
        var deleteSecDocument = function (document, module, secDocument) {
            DialogsFactory.confirm('global.documents.confirmDeleteDocumentTitle', 'global.documents.confirmDeleteDocumentContent')
                .then(function() {
                    $rootScope.loading(true);
                    SecFactory.deleteSessionDocument(secDocument)
                        .then(function() {
                            $rootScope.loading(false);
                            broadcastDocumentActionButtonExecuted('deleteSecDocument', secDocument);
                        }).catch(function(error) {
                            $rootScope.loading(false);
                            broadcastDocumentActionButtonError('deleteSecDocument', error);
                    });
                });
        }
        
        var unbindDocument = function(dossierTransactionDocument) {
            broadcastDocumentActionButtonExecuted('unbindDocument', dossierTransactionDocument);
        }
        
        var replaceDocumentModal = function(document) {
            var replaceDocument = function() {
                if(this.annexaFormly.form.$valid) {
                    $rootScope.loading(true)
                    var self = this;
                    var data = this.annexaFormly.model.modal_body;
                    var customFields = document.customFields;
                    var archiveClassification = document.archiveClassification;

                    DccumentsFactory.updateDocumentFile(data.file, document.id)
                        .then(function (data) {
                            var doc = data;
                            doc.archiveClassification = !archiveClassification ? {} : archiveClassification;
                            doc.customFields = customFields;
                            doc.content = data.metadataSearch;
                            $rootScope.$broadcast('docContentLoaded', doc);
                            $rootScope.loading(false);
                            broadcastDocumentActionButtonExecuted('replaceDocumentModal', doc);
                            self.close();
                        }).catch(function(error) {
                            $rootScope.loading(false);
                            broadcastDocumentActionButtonError('replaceDocumentModal', error);
                    });
                }
            }

            var modal = angular.copy(globalModals.uploadFile);

            if(document.docStatus == 'NEW'){
                modal.title = 'global.literals.attachDocument';
            }else{
                modal.title = 'global.documents.new.replaceDocument';
            }

            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = {};
            modal.annexaFormly.options = {};
            modal.annexaFormly.options.formState = {readOnly: false};
            AnnexaFormlyFactory.showModal('modalAddAttachment', modal, replaceDocument, false);
        }
        
        var allowGenerateFromContentDocumentType = function(document, module, transaction, secDocument, archiveClassification, dossier) {
        	var documentType = undefined;
        	if( document && document.type && document.type.id){
        		documentType = $linq(GlobalDataFactory.documentTypes).firstOrDefault(undefined, "x => x.id == "+document.type.id);
        	}
            if( documentType && documentType.allowGenerateFromContentDocumentTypes && documentType.allowGenerateFromContentDocumentTypes.length > 0) {
				var documentTypes = [];
				_.forEach(documentType.allowGenerateFromContentDocumentTypes, function(son){
					var dtype = $linq(GlobalDataFactory.documentTypes).firstOrDefault(undefined, "x => x.id == "+son.documentTyperelatedJSONId);
					if(dtype && dtype.documentTemplateSelection && dtype.documentTemplateSelection != 'NOT_REQUIRED'){
		                if(dtype.profiles && dtype.profiles.length > 0 && $rootScope.LoggedUser && $rootScope.LoggedUser.userProfiles && $rootScope.LoggedUser.userProfiles.length > 0){
		                	var profiles = $linq(dtype.profiles).intersect($rootScope.LoggedUser.userProfiles, function(x,y){
                                if(y.profile){
                                    return (x.profile.id == y.profile.id);
                                }
                                else{
                                    return true;
                                }
                            }).toArray();
                            if(profiles && profiles.length > 0){
        						var dtypeAux = angular.copy(dtype);
        						dtypeAux.documentFileAttachment = 'NOT_REQUIRED';
        						documentTypes.push(dtypeAux);
                            }
		                }else if(!dtype.profiles || dtype.profiles.length == 0){
		                	var dtypeAux = angular.copy(dtype);
    						dtypeAux.documentFileAttachment = 'NOT_REQUIRED';
    						documentTypes.push(dtypeAux);
		                }
					}
				});
				if(documentTypes && documentTypes.length > 0 && document && document.id){
					var commitFunction = undefined;
					if(transaction && transaction.id){
						var dossierTransaction = -1;
						commitFunction = function(data){
							$rootScope.$broadcast('allowGenerateFromContentDocumentTypeExecutedTram', { transactionId: ((dossierTransaction && dossierTransaction.id)?dossierTransaction.id:transaction.id), data: data });
						}
						
						var dossierTransactions = [];
    		            if(dossier && dossier.dossierTransactions && $linq(dossier.dossierTransactions).count("x => x.actual") > 0){
    		            	dossierTransactions = 	$linq(dossier.dossierTransactions).where("x => x.actual").toArray();
    		            }
    		            if(dossierTransactions.length > 0){
    		            	var modalDT = angular.copy(globalModals.selectDossierTransactionToAddDocument);
    		            	modalDT.alerts = [];
    		            	modalDT.annexaFormly.model = {};
    		            	modalDT.annexaFormly.model.modal_body = {};
    		            	modalDT.annexaFormly.options = {};
    		            	modalDT.annexaFormly.options.formState = {readOnly: false};
    		            	modalDT.annexaFormly.model.modal_body.dossierTransactions = dossierTransactions;
        		        	
    		            	var selectDT = function () {
    			            	if(this.annexaFormly.model.modal_body.dossierTransactionAdded && this.annexaFormly.model.modal_body.dossierTransactionAdded > 0){
    		            			dossierTransaction = $linq(dossierTransactions).firstOrDefault(undefined, "x => x.id == "+this.annexaFormly.model.modal_body.dossierTransactionAdded);
    		            			if(dossierTransaction && dossierTransaction.id){
    		            				if(this.annexaFormly.model.modal_body.createdDocumentType == 'tram'){
											var boxDefinition = {documents:{dossier: dossier}};
											TramNewFactory.getDossierThirds(dossier.id).then(function(data) {
    		            						AnnexaEntityFactory.showNewDocumentFromTemplateModal(dossierTransaction, new SortedArray(TramNewFactory.TramTemplates, Language.getActiveColumn()).sort(), new SortedArray(documentTypes, Language.getActiveColumn()).sort(), boxDefinition, (transaction.id == dossierTransaction.id), document.id, ((data)?JSOG.decode(data):[]));
											}).catch(function(error) {
												AnnexaEntityFactory.showNewDocumentFromTemplateModal(dossierTransaction, new SortedArray(TramNewFactory.TramTemplates, Language.getActiveColumn()).sort(), new SortedArray(documentTypes, Language.getActiveColumn()).sort(), boxDefinition, (transaction.id == dossierTransaction.id), document.id, []);
											})
    		            				}else{
    		            					DccumentsFactory.openNewDocument(undefined, commitFunction, module, undefined, undefined, ((dossier && dossier.archiveClassification)?dossier.archiveClassification:((archiveClassification)?archiveClassification:((document.archiveClassification)?document.archiveClassification:undefined))), DccumentsFactory.selectedType.type, dossierTransaction, documentTypes, document.id);
    		            				}
    		            			}
    			            	}
    		            		modalDT.close();
    			            };
    			            AnnexaFormlyFactory.showModal("modalProposalToAddDocument", modalDT, selectDT, false);
    		            }else{
    		            	DccumentsFactory.openNewDocument(undefined, commitFunction, module, undefined, undefined, ((archiveClassification)?archiveClassification:((document.archiveClassification)?document.archiveClassification:undefined)), DccumentsFactory.selectedType.type, transaction, documentTypes, document.id);    		            	
    		            }
					}else if(secDocument && secDocument.session && secDocument.session.id){
						commitFunction = function(data){
							$rootScope.$broadcast('allowGenerateFromContentDocumentTypeExecutedSec', { sessionId: secDocument.session.id, data: data });
						}
						DccumentsFactory.openNewDocument(undefined, commitFunction, module, undefined, undefined, ((archiveClassification)?archiveClassification:((document.archiveClassification)?document.archiveClassification:undefined)), DccumentsFactory.selectedType.type, transaction, documentTypes, document.id);
					}else{
						DccumentsFactory.openNewDocument(undefined, commitFunction, module, undefined, undefined, ((archiveClassification)?archiveClassification:((document.archiveClassification)?document.archiveClassification:undefined)), DccumentsFactory.selectedType.type, transaction, documentTypes, document.id);
					}
				}
            }
        }

        var markAsWorkDocument = function (dossierTransactionDocument) {
            if(dossierTransactionDocument && dossierTransactionDocument.id){
            	$rootScope.loading(true);
	            TramFactory.updateRelationTypeDossierTransactionDocument(dossierTransactionDocument.id, 'WORK_DOCUMENT')
	                .then(function(data) {
	                    $rootScope.loading(false);
	                    broadcastDocumentActionButtonExecuted('updateRelationTypeDossierTransactionDocument', data);
	                }).catch(function(error) {
	                    $rootScope.loading(false);
	                    broadcastDocumentActionButtonError('updateRelationTypeDossierTransactionDocument', error);
	            });
            }else{
            	dossierTransactionDocument.relationType = 'WORK_DOCUMENT';
            	broadcastDocumentActionButtonExecuted('updateRelationTypeDossierTransactionDocument', dossierTransactionDocument);
            }
        }
        
        var markAsDocument = function (dossierTransactionDocument) {
        	if(dossierTransactionDocument && dossierTransactionDocument.id){
                $rootScope.loading(true);
	            TramFactory.updateRelationTypeDossierTransactionDocument(dossierTransactionDocument.id, 'DOCUMENT')
	                .then(function(data) {
	                    $rootScope.loading(false);
	                    broadcastDocumentActionButtonExecuted('updateRelationTypeDossierTransactionDocument', data);
	                }).catch(function(error) {
	                    $rootScope.loading(false);
	                    broadcastDocumentActionButtonError('updateRelationTypeDossierTransactionDocument', error);
	            });
	        }else{
	        	dossierTransactionDocument.relationType = 'DOCUMENT';
	        	broadcastDocumentActionButtonExecuted('updateRelationTypeDossierTransactionDocument', dossierTransactionDocument);
	        }
        }
        
        var toPaperBin = function (dossierTransactionDocument, toPaperBin) {
        	if(dossierTransactionDocument && dossierTransactionDocument.id){
                $rootScope.loading(true);
	            TramFactory.updatePaperBinDossierTransactionDocument(dossierTransactionDocument.id, toPaperBin)
	                .then(function(data) {
	                    $rootScope.loading(false);
	                    broadcastDocumentActionButtonExecuted('updateRelationTypeDossierTransactionDocument', data);
	                }).catch(function(error) {
	                    $rootScope.loading(false);
	                    broadcastDocumentActionButtonError('updateRelationTypeDossierTransactionDocument', error);
	            });
	        }else{
	        	dossierTransactionDocument.paperBin = toPaperBin;
	        	broadcastDocumentActionButtonExecuted('updateRelationTypeDossierTransactionDocument', dossierTransactionDocument);
	        }
        }
        
        var manuallyAddStampDecree = function(document) {
        	DialogsFactory.confirm('global.documents.confirmManuallyAddStampDecreeTitle', 'global.documents.confirmManuallyAddStampDecreeContent')
	            .then(function() {
	                $rootScope.loading(true);
	                DccumentsFactory.manuallyAddStampDecree(document.id)
	                    .then(function() {
	                        $rootScope.loading(false);
	                        broadcastDocumentActionButtonExecuted('replaceDocumentModal', document.id);
	                    }).catch(function(error) {
	                        $rootScope.loading(false);
	                        broadcastDocumentActionButtonError('manuallyAddStampDecree', error);
                            DialogsFactory.error('global.errors.error', $filter('translate')('DIALOGS_ERROR_MSG'));
	                });
	            });
        }

		var associateDocumentToThird = function(document, transaction, dossier, thirds) {
			var associateAux = function(document, thirds){
				if(document && document.id){
					var docs = [{id:document.id}];
					var modal = angular.copy(globalModals.documentRelatedToThird);
					modal.annexaFormly.model = {};
					var thirdsToAdd = angular.copy(((thirds)?thirds:[]));
				    modal.annexaFormly.model.modal_body = {thirdsToAdd: angular.copy(thirdsToAdd)};
				    modal.annexaFormly.options = {};
					var saveDocumentRelatedToThird = function(){
						var self = this;
						if(self.annexaFormly && self.annexaFormly.model && self.annexaFormly.model.modal_body){
							var model = self.annexaFormly.model.modal_body;
							var thsAdded = [];
							_.forEach(thirdsToAdd, function(th){
								if(th && th.id){
									if(th.third && th.third.id && model['thirds_'+th.id]){
										thsAdded.push({thirdId: th.third.id, documents:docs});
									}
								}else if(th.third && th.third.id && model['thirds_'+th.third.id]){
									thsAdded.push({thirdId: th.third.id, documents:docs});
								}
							});
							if(thsAdded && thsAdded.length > 0){
								$http({
					                method: 'PUT',
					                url: './api/thirds/relatedDocuments', 
									data: thsAdded
					            }).success(function(data, status) {
									self.close();
								}).error(function(msg,code) {
									DialogsFactory.error($filter('translate')('global.literals.associateDocumentToThirdError3'));
					            });
							}
						}
					}
					AnnexaFormlyFactory.showModal('modalDocumentRelatedToThird', modal, saveDocumentRelatedToThird, false);	
				}else{
					DialogsFactory.error($filter('translate')('global.literals.associateDocumentToThirdError2'));
				}
			}
			
        	if(thirds && thirds.length > 0){
				associateAux(document, thirds);
			}else if(transaction && dossier && dossier.id){
				TramNewFactory.getDossierThirds(dossier.id).then(function(data) {
		            associateAux(document, JSOG.decode(data));
				}).catch(function(error) {
					DialogsFactory.error($filter('translate')('global.literals.associateDocumentToThirdError'));
				})
			} else{
				DialogsFactory.error($filter('translate')('global.literals.associateDocumentToThirdError'));
			}
        }

		var regenerateDocument = function(document) {
        	DialogsFactory.confirm('global.documents.new.regenerateDocument', 'global.documents.new.regenerateDocumentBody')
	            .then(function() {
	                $rootScope.loading(true);
	                DccumentsFactory.regenerateDocument(document.id)
	                    .then(function() {
	                        $rootScope.loading(false);
	                        broadcastDocumentActionButtonExecuted('returnToDraft', document);
	                    }).catch(function(error) {
	                        $rootScope.loading(false);
							if(error && error.message && error.message =="Regenerate information not exist"){
								DialogsFactory.error('global.documents.new.regenerateDocumentInformationError', $filter('translate')('DIALOGS_ERROR_MSG'));
							}else{
                            	DialogsFactory.error('global.documents.new.regenerateDocumentError', $filter('translate')('DIALOGS_ERROR_MSG'));
							}
	                });
	            });
        }
        
        var downloadMultipleRelatedDocuments = function (relatedDocuments) {            
            var documentsToDownload = {};
            var confirmBody = '';
            confirmBody = $filter('translate')('global.documents.confirmDownloadDocumentsList');
            confirmBody += '<br/><ul>';
            var docs = [];
            angular.forEach(relatedDocuments, function (docRelated) {
            	var doc = docRelated.document;
                if (doc) {
                	confirmBody += '   <li>' + doc.name + '</li>';
                	docs.push({id:doc.id});
                }
            });
            docs = $linq(docs).orderBy("x => x.id").toArray();
            documentsToDownload.documents = JSOG.encode(docs);
            documentsToDownload.languageColumn = Language.getActiveColumn();
            confirmBody += '</ul>';
            DialogsFactory.confirm('global.literals.downloadDocuments', confirmBody)
                .then(function() {
                    $rootScope.loading(true);
                    $http({
                        url: './api/doc/generateZip',
                        method: 'POST',
                        data: documentsToDownload,
                        headers: {
                            accept: 'application/zip'
                        },
                        responseType: 'arraybuffer',
                        cache: false
                    }).then(function (data, status, headers) {
                        var contentType = data.headers('content-type');
                        if (!contentType) {
                            contentType = 'application/zip';
                        }
                        var name = data.headers('content-disposition');
                        if (name) {
                            name = name.split(";");
                            if (name && name.length > 1) {
                                name = name[1].trim().split("=");
                                if (name && name.length > 1) {
                                    name = name[1];
                                    name = name.replace(/"/g, '');
                                } else {
                                    name = "documents.zip";
                                }
                            } else {
                                name = "documents.zip";
                            }
                        } else {
                            name = "documents.zip";
                        }
                        var file = new Blob([data.data], {type: contentType});
                        if (window.navigator.msSaveOrOpenBlob) {
                            try {
                                window.navigator.msSaveOrOpenBlob(file, name);
                            } catch (e) {
                                console.log(e.message);
                            }
                        } else {

                            var linkElement = document.createElement('a');
                            try {
                                var url = window.URL.createObjectURL(file);
                                linkElement.setAttribute('href', url);
                                linkElement.setAttribute("download", name);
                                var clickEvent = new MouseEvent("click", {
                                    "view": window,
                                    "bubbles": true,
                                    "cancelable": false
                                });
                                linkElement.dispatchEvent(clickEvent);
                            } catch (ex) {
                                console.log(ex);
                            }
                        }
                        self.close();
                    }).catch(function (error) {
                        DialogsFactory.error($filter('translate')('global.errors.errorGeneratingZip'), $filter('translate')('DIALOGS_ERROR_MSG'));
                    });
            });
        }

		var associateDocumentToThirdMultipleDossierTransactionDocuments = function (documents, transaction, dossier) {
			if(transaction && dossier && dossier.id){
				TramNewFactory.getDossierThirds(dossier.id).then(function(data) {
					if(documents && documents.length > 0){
						var thirds = ((data && data.length > 0)?JSOG.decode(data):[]);
						var docs = [];
						_.forEach(documents, function(doc){
							docs.push({id:doc.id});
						});
						var modal = angular.copy(globalModals.documentRelatedToThird);
						modal.annexaFormly.model = {};
					    modal.annexaFormly.model.modal_body = {};
					    modal.annexaFormly.model.modal_body = {thirdsToAdd: angular.copy(thirds)};
					    
					    modal.annexaFormly.options = {};
						var saveDocumentsRelatedsToThird = function(){
							var self = this;
							if(self.annexaFormly && self.annexaFormly.model && self.annexaFormly.model.modal_body){
								var model = self.annexaFormly.model.modal_body;
								var thsAdded = [];
								_.forEach(thirds, function(th){
									if(th && th.id && th.third && th.third.id && model['thirds_'+th.id]){
										thsAdded.push({thirdId: th.third.id, documents:docs});
									}
								});
								if(thsAdded && thsAdded.length > 0){
									$http({
						                method: 'PUT',
						                url: './api/thirds/relatedDocuments', 
										data: thsAdded
						            }).success(function(data, status) {
										self.close();
									}).error(function(msg,code) {
										DialogsFactory.error($filter('translate')('global.literals.associateDocumentToThirdError3'));
						            });
								}
							}
						}
						AnnexaFormlyFactory.showModal('modalDocumentRelatedToThird', modal, saveDocumentsRelatedsToThird, false);	
					}else{
						DialogsFactory.error($filter('translate')('global.literals.associateDocumentToThirdError2'));
					}
				}).catch(function(error) {
					DialogsFactory.error($filter('translate')('global.literals.associateDocumentToThirdError'));
				})
			} else{
				DialogsFactory.error($filter('translate')('global.literals.associateDocumentToThirdError'));
			}
        }

        var broadcastDocumentActionButtonExecuted = function(button, data) {
            $rootScope.$broadcast('documentActionButtonExecuted', { button: button, data: data });
        };

        var broadcastDocumentActionButtonExecuting = function(button, data) {
            $rootScope.$broadcast('documentActionButtonExecuting', { button: button, data: data });
        };

        var broadcastDocumentActionButtonError = function (button, error) {
            $rootScope.$broadcast('documentActionButtonError', { button: button, error: error });
        };

        factory.getMultipleActionsButton = function(options, dossierTransactions, module, documents, dossier, thirds, secDocuments) {
            var buttons = [];
            var onlyDocuments = undefined;
            var actualTransaction = undefined;
            if(dossierTransactions){
            	var onlyDocuments = $linq(dossierTransactions).select("x => x.document").toArray();
            	var onlyTransactions = $linq(dossierTransactions).where("x => x.dossierTransaction").select("x => x.dossierTransaction").toArray();
            	if(onlyTransactions.length > 0){
            		actualTransaction = $linq(onlyTransactions).firstOrDefault(undefined, "x => x.actual");
            	}
	
	            if(!actualTransaction && dossierTransactions.length > 0 && TramNewFactory.Dossier && TramNewFactory.Dossier.dossierTransactions) {
	                actualTransaction = $linq(TramNewFactory.Dossier.dossierTransactions).firstOrDefault(undefined, "x => x.actual");
	            }
            }else if(module == 'SEC'){
            	var onlyDocuments = documents;
            }

            _.forEach(options, function(option) {
                switch (option) {
                    case 'SendToSign':
                        buttons.push({
                            text: $filter('translate')('global.documents.datatable.enviarFirmar'),
                            action: function() {
                                sendToSign(onlyDocuments, dossier);
                            }
                        });
                        break;
                    case 'CreateDiligenceStamp':
                        buttons.push({
                            text: $filter('translate')('global.documents.datatable.createDiligenceStamp'),
                            action: function() {
                            	createDiligenceStamp(onlyDocuments);
                            }
                        });
                        break;
                    case 'CreateOutputReg':
                        buttons.push({
                            text: $filter('translate')('global.tram.literals.createOutputRegister'),
                            action: function () {
                                createRegisterEntry(onlyDocuments, actualTransaction, dossier);
                            }
                        });
                        break;
                    case 'Notification':
                    	if(!module || module != 'SEC'){
	                        buttons.push({
	                            text: $filter('translate')('global.tram.literals.createNotification'),
	                            action: function () {
	                                notification(dossierTransactions, dossier);
	                            }
	                        });
                    	}
                        break;
                    case 'Email':
                        buttons.push({
                            text: $filter('translate')('global.documents.datatable.enviarMail'),
                            action: function() {
                                undefined
                            }
                        })
                        break;
                    case 'Complete':
                        buttons.push({
                            text: $filter('translate')('global.literals.completeDocument'),
                            action: function() {
                                complete(onlyDocuments);
                            }
                        });
                        break;
                    case 'Publish':
                        buttons.push({
                            text: $filter('translate')('global.literals.publish'),
                            action: function() {
                                publishDocuments(onlyDocuments, dossier);
                            }
                        });
                        break;
                    case 'RemoveDocuments':
                    	if(module == 'SEC'){
                    		buttons.push({
                                text: $filter('translate')('global.literals.remove'),
                                action: function() {
                                	deleteMultipleSessionDocuments(secDocuments);
                                }
                            });
                    	}else{
                    		buttons.push({
                                text: $filter('translate')('global.literals.remove'),
                                action: function() {
                                	deleteMultipleDossierTransactionDocuments(dossierTransactions);
                                }
                            });
                    	}
                        
                        break;
                    case 'DownloadSelected':
                    	if(module == 'SEC'){
                    		buttons.push({
                                text: $filter('translate')('global.buttons.document.downloadDocuments'),
                                action: function() {
                                	 downloadMultipleRelatedDocuments(secDocuments);
    	                        }
    	                    });
                    	}else{
                    		buttons.push({
                                text: $filter('translate')('global.buttons.document.downloadDocuments'),
                                action: function() {
                                	 downloadMultipleRelatedDocuments(dossierTransactions);
    	                        }
    	                    });
                    	}
                        
	                	break;
					case 'AssociateDocumentToThird':
                        buttons.push({
                            text: $filter('translate')('global.literals.associateDocumentToThird'),
                            action: function() {
	                            associateDocumentToThirdMultipleDossierTransactionDocuments(onlyDocuments, actualTransaction, dossier);
	                        }
	                    });
	                	break;
                }
            });

            return buttons;
        }

        factory.executeOption = function(option, document, dossierTransactionDocument, transaction, module, secDocument, objectToCall, dossier, thirds) {
            switch (option) {
                case 'EditOnlineMicrosoft':
                    broadcastDocumentActionButtonExecuting('editOnline');
                    editOnline('microsoft', document);
                    break;
                case 'EditOnlineLibreOffice':
                    broadcastDocumentActionButtonExecuting('editOnline');
                    editOnline('libreoffice', document);
                    break;
                case 'SendToSign':
                    sendToSign(document, dossier);
                    break;
                case 'CreateDiligenceStamp':
                	createDiligenceStamp(document);
                    break;
                case 'CreateOutputReg':
                    createRegisterEntry(document, transaction, dossier);
                    break;
                case 'Notification':
                    notification(dossierTransactionDocument, dossier);
                    break;
                case 'Publish':
                    publish(document, transaction);
                    break;
                case 'Download':
                    download(document, false);
                    break;
                case 'DownloadPDF':
                    download(document, true);
                    break;
                case 'GenerateZIP':
                    generateZIP(document);
                    break;
                case 'Print':
                    print(document);
                    break;
                case 'Complete':
                    complete(document);
                    break;
                case 'ReturnToDraft':
                    returnToDraft(document);
                    break;
                case 'Remove':
                    deleteDocument(document);
                    break;
                case 'RemoveDossierTransaction':
                    deleteDossierTransactionDocument(document, transaction);
                    break;
                case 'RemoveSecDocument':
                    deleteSecDocument(document, module, secDocument);
                    break;
                case 'AttachReplace':
                    replaceDocumentModal(document);
                    break;
                case 'AllowGenerateFromContentDocumentType':
                	allowGenerateFromContentDocumentType(document, module, transaction, secDocument, objectToCall, dossier);
                	break;
                case 'MarkAsWorkDocument':
                	markAsWorkDocument(dossierTransactionDocument);
                	break;
                case 'MarkAsDocument':
                	markAsDocument(dossierTransactionDocument);
                	break;
                case 'SendToPaperBin':
                	toPaperBin(dossierTransactionDocument, true);
                	break;
                case 'RemoveFromPaperBin':
                	toPaperBin(dossierTransactionDocument, false);
                	break;
                case 'ManuallyAddStampDecree':
                	manuallyAddStampDecree(document);
                	break;
				case 'AssociateDocumentToThird':
                	associateDocumentToThird(document, transaction, dossier, thirds);
                	break;
				case 'RegenerateDocument':
                	regenerateDocument(document);
                	break;
				case 'UnbindDocument':
					unbindDocument(dossierTransactionDocument);
					break;
            }
        }

        //endregion
        return factory;
    }])
    .component('annexaDocumentActionsButton', {
        templateUrl: './components/doc/annexa-document-actions-button/annexa-document-actions-button.html',
        controller: ['$scope', '$rootScope', 'AnnexaPermissionsFactory', 'DccumentsFactory', 'HelperService', 'RestService', 'AnnexaEntityFactory', 'AnnexaModalFactory', 'globalModals', '$http', 'DialogsFactory', 'AnnexaFormlyFactory', 'TramFactory', 'SignLocalFactory', '$filter', 'ErrorFactory', '$state', 'AnnexaDocumentActionsButtonFactory', '$state', function ($scope, $rootScope, AnnexaPermissionsFactory, DccumentsFactory, HelperService, RestService, AnnexaEntityFactory, AnnexaModalFactory, globalModals, $http, DialogsFactory, AnnexaFormlyFactory, TramFactory, SignLocalFactory, $filter, ErrorFactory, $state, AnnexaDocumentActionsButtonFactory, $state) {
            var vm = this;
            vm.isArchivedDossier = false;
            vm.isOpened = false;
            
            vm.topButtonTarget = '';
            if(vm.openInNewWindow){
            	vm.topButtonTarget = '_blank';
            }
            vm.transaction = undefined;
            vm.actualTransaction = undefined;
			vm.dossier = undefined;
            vm.LoggedUser = $rootScope.LoggedUser;
            vm.showManuallyAddStampDecree = false;
            vm.showAssociateDocumentToThird = false;
            vm.dossierTransactions = [];
            vm.updateDocument = function(){
            	if(vm.document && vm.document.docStatus && vm.document.docStatus == 'ARCHIVED' && vm.isArchivedDossier){
            		RestService.findOne('Document', vm.document.id, 'annexa-document-actions-button-reload')
                    .then(function(data) {
                    	$rootScope.$broadcast('annexaDocumentActionsButtonReload', { doc: JSOG.decode(data.data) });
                    	calculateIfIsArchivedDossier();
                        vm.isOpened = false;
                    });
            	}
            }
            vm.openAchivedDocument = function(){
            	if(vm.document && vm.document.docStatus && vm.document.docStatus == 'ARCHIVED' && vm.isArchivedDossier){
            		if($state && $state.current && $state.current.name){
            			if($state.current.name == "annexa.archive.transfer.edit"){
            				vm.isOpened = true;
            				window.open($state.href('annexa.archive.transfer.edit.document', { "document": vm.document.id }), '_blank');
            			}else if($state.current.name == "annexa.archive.rejected.edit"){
            				vm.isOpened = true;
            				window.open($state.href('annexa.archive.rejected.edit.document', { "document": vm.document.id }), '_blank');
            			}else if($state.current.name == "annexa.archive.archive.edit"){
            				vm.isOpened = true;
            				window.open($state.href('annexa.archive.archive.edit.document', { "document": vm.document.id }), '_blank');
            			}else if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.close_and_archive && $rootScope.app.configuration.close_and_archive.showArchivedDocument && $state.current.name == "annexa.archive.archive.see"){
            				$scope.showLoadingdiv = true;
			                var windowReference = window.open();
			                DccumentsFactory.getDocContent(vm.document.id).then(function (data) {
		                        $scope.showLoadingdiv = false;
		                        windowReference.location = data;
		                    }).catch(function (error) {
			                    $scope.showLoadingdiv = false;
			                    windowReference.close();
			                });
            			}
            		}
            	}
            }
            var calculateIfIsArchivedDossier = function(){
            	if(vm.document && vm.document.docStatus == 'ARCHIVED' && 
            			(
            					$state.current.name.startsWith("annexa.archive.transfer.edit") || 
            					$state.current.name.startsWith("annexa.archive.rejected.edit") || 
            					$state.current.name.startsWith("annexa.archive.archive.edit")  ||
								($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.close_and_archive && $rootScope.app.configuration.close_and_archive.showArchivedDocument && $state.current.name.startsWith("annexa.archive.archive.see"))
            			) 
            	){
                	vm.isArchivedDossier = true;
    			}
            }
            
            this.$onInit = function() {
                if(vm.disabled = undefined) {
                    disabled = false;
                }
				if(vm.module && vm.module == 'EXPED'){
					vm.showAssociateDocumentToThird = true;
				}
				if(vm.dossierTransactionDocument) {
                    vm.topButtonTarget = '_blank';
                    vm.document = vm.dossierTransactionDocument.document;
                    calculateIfIsArchivedDossier();
					if(vm.transactionComponent && vm.transactionComponent.dossierTransaction){
						vm.transaction = vm.transactionComponent.dossierTransaction;
                        vm.actualTransaction = vm.transaction.actual;
						if(vm.transactionComponent && vm.transactionComponent.dossierTransactionsComponent && vm.transactionComponent.dossierTransactionsComponent.dossierTabsComponent && vm.transactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent && vm.transactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier){
							vm.dossier = vm.transactionComponent.dossierTransactionsComponent.dossierTabsComponent.dossierComponent.dossier;
						}
                    }else if(vm.transactionInit){
                        vm.transaction = vm.transactionInit;
                        vm.actualTransaction = vm.transaction.actual;
                        vm.dossier = vm.transaction.dossier;
                    }
                    canManuallyAddStampDecree(vm.dossierTransactionDocument.document.id);
                } else if(vm.document){
                	calculateIfIsArchivedDossier();
                    canManuallyAddStampDecree(vm.document.id);
                }
            }
            if(!vm.dossier && !vm.transaction && vm.document && vm.document.dossierTransactionDocuments && vm.document.dossierTransactionDocuments.length == 1){
            	vm.dossier = vm.document.dossierTransactionDocuments[0].dossierTransaction.dossier;
            	vm.transaction = vm.document.dossierTransactionDocuments[0].dossierTransaction;
            }else if(!vm.dossier && !vm.transaction && vm.document && vm.document.dossierTransactionDocuments && vm.document.dossierTransactionDocuments.length > 1){
            	vm.dossierTransactions = $linq(vm.document.dossierTransactionDocuments).select("x => x.dossierTransaction").toArray();
            }
            //region General

            var canManuallyAddStampDecree = function(documentId) {
            	var checkCanManuallyAddStampDecree = true;
            	if(vm.list && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.manually_add_stamp_decree_lists){
            		checkCanManuallyAddStampDecree = $rootScope.app.configuration.manually_add_stamp_decree_lists.value;
            	}
            	if(checkCanManuallyAddStampDecree) {
	               	DccumentsFactory.canManuallyAddStampDecree(documentId)
		      			.then(function(data) {
		      				vm.showManuallyAddStampDecree = data;
		      			}).catch(function(error) {
		      				vm.showManuallyAddStampDecree = false;
	      			})
            	} else {
            		vm.showManuallyAddStampDecree = false;
            	}
            }
           
            
            var evaluateShowWithoutDocumentProfiles = function() {
                var continueEvaluation = true;
                return vm.document && vm.document.profiles && vm.document.type != 'folder' && continueEvaluation;
            }

            vm.showButton = function() {
                return evaluateShowWithoutDocumentProfiles();
            }

            vm.showDropdown = function() {
            	if(vm.document && vm.document.docStatus && vm.document.docStatus == 'ARCHIVED'){
            		return false;
            	}else if($state.current.name == 'annexa.tram.dossierRightAccess.viewdossier' || $state.current.name == 'annexa.archive.finally.see'  || $state.current.name == 'annexa.archive.close.see'  || $state.current.name == 'annexa.archive.archive.see') {
					return false;
				}else if($state.current.name == 'annexa.tram.view.viewdossier' && (!vm.dossier || !vm.dossier.dossierStatus || vm.dossier.dossierStatus != 'FINISHED')) {
					return false;
				}else if($state.current.name == 'annexa.tram.view.viewdossier' && vm.dossier && vm.dossier.dossierStatus == 'FINISHED') {
					return ( _.contains(['NEW', 'NEW_AND_ATTACHMENT', 'COMPLETE', 'PENDING_SIGN', 'SIGNED', 'REJECTED'], vm.document.docStatus) && vm.showOption('Publish'));
				}else{
	                if($state.current.name != 'annexa.doc.documents.view'){
	                    return _.contains(['NEW', 'NEW_AND_ATTACHMENT', 'COMPLETE', 'PENDING_SIGN', 'SIGNED', 'REJECTED'], vm.document.docStatus) && havePermissionFromDossierProfilesDocument();
	                }else{
	                    return _.contains(['NEW', 'NEW_AND_ATTACHMENT', 'COMPLETE', 'PENDING_SIGN', 'SIGNED', 'REJECTED'], vm.document.docStatus);
	                }
            	}
            }

            $scope.$on('documentActionButtonExecuted', function (event, args) {
                switch (args.button) {
                    case 'delete':
                        if(!vm.list) {
                            $state.transitionTo('annexa.doc.documents.all');
                        }
                        break;
                    case 'complete':
                        if(!vm.list) {
                            $state.transitionTo('annexa.doc.documents.all');
                        }
                        break;
                    case 'replaceDocumentModal':
                        $state.reload();
                        break;     
                }
            });

            //endregion

            //region Botons

            var broadcastDocumentActionButtonExecuting = function(button, data) {
                $rootScope.$broadcast('documentActionButtonExecuting', { button: button, data: data })
            };

            vm.showOption = function(option) {
                var havePermissionFromDossier = havePermissionFromDossierProfilesDocument();
                var chkShowOption = false;
                
                if($state.current.name == 'annexa.tram.view.viewdossier'  || $state.current.name == 'annexa.tram.dossierRightAccess.viewdossier' || $state.current.name == 'annexa.archive.finally.see'  || $state.current.name == 'annexa.archive.close.see'  || $state.current.name == 'annexa.archive.archive.see') {
					if(option == 'Publish' && $state.current.name == 'annexa.tram.view.viewdossier' && vm.dossier && vm.dossier.dossierStatus == 'FINISHED'){
                		chkShowOption = true;
					}
                } else {
                	chkShowOption = true;
                }
                
                if(chkShowOption) {
                	if(havePermissionFromDossier){
                        return AnnexaDocumentActionsButtonFactory.showOption(option, vm.document, vm.dossierTransactionDocument, vm.actualTransaction, vm.list, vm.disabled, vm.module, vm.secDocument, vm.showManuallyAddStampDecree, vm.showAssociateDocumentToThird, vm.dossier);
                    }else{
                        if(_.contains(['DownloadPDF', 'Download', 'GenerateZIP', 'Print', 'Email', 'CreateDiligenceStamp'], option) ){
                            return AnnexaDocumentActionsButtonFactory.showOption(option, vm.document, vm.dossierTransactionDocument, vm.actualTransaction, vm.list, vm.disabled, vm.module, vm.secDocument, vm.showManuallyAddStampDecree, vm.showAssociateDocumentToThird, vm.dossier);
                        }else{
                            return false;
                        }
                    }
                }
            }

            $scope.$on('broadcastDocumentActionButtonExecuted', function (event, args) {
                if(args.button && args.button == 'replaceDocumentModal') {
                    vm.document = args.data;
                }
            });

            vm.executeOption = function (option) {
                AnnexaDocumentActionsButtonFactory.executeOption(option, vm.document, vm.dossierTransactionDocument, vm.transaction, vm.module, vm.secDocument, vm.objectToCall, vm.dossier);
            }

            var havePermissionFromDossierProfilesDocument = function(){
                var havePermission = false;
                if(vm.transaction){
                    _.forEach(vm.transaction.processProfiles, function (item) {
                        if(!havePermission){
                            havePermission = AnnexaPermissionsFactory.haveSinglePermissionDual('process_dossier', item.profile)
                        }
                    })
                    if(!havePermission && vm.dossier && vm.dossier.dossierTransactions){
                        _.forEach(vm.dossier.dossierTransactions, function (dt) {
                            _.forEach(dt.processProfiles, function (dtItem) {
                                if (!havePermission) {
                                    havePermission = AnnexaPermissionsFactory.haveSinglePermissionDual('process_dossier', dtItem.profile);
                                }
                            });
                        });
                    }
                    if(!havePermission && vm.dossier && vm.dossier.dossierResponsibleProfiles) {
                    	_.forEach(vm.dossier.dossierResponsibleProfiles, function (drp) {
                            if (!havePermission) {
                                havePermission = AnnexaPermissionsFactory.haveSinglePermissionDual('process_dossier', drp.profile);
                            }
                        });
                    }
                }
                if(!havePermission && vm.document){
                    if(vm.document.globalProcessProfileIds){
                        var userProfiles = $linq($rootScope.LoggedUser.userProfiles).select("x => x.profile").toArray();
                        var intersect = $linq(userProfiles).intersect(vm.document.globalProcessProfileIds, "(x,y) => x == y.id").toArray();
                        _.forEach(intersect, function (dtItem) {
                            if (!havePermission) {
                                havePermission = AnnexaPermissionsFactory.haveSinglePermissionDual('process_dossier', dtItem);
                            }
                        });
                    }
                    if(!havePermission) {
                        havePermission = AnnexaPermissionsFactory.haveSinglePermissionDual('view_documents', vm.document.profiles);
                    }
                }
                return havePermission;
            }

            //endregion
        }],
        bindings: {
            document: '=?',
            list: '=',
            dossierTransactionDocument: '=?',
            secDocument: '=?',
            transactionInit: '=?',
			transactionComponent: '=?',
            openInNewWindow: '=?',
            module: '@?',
            objectToCall: '=?'
        }
    })